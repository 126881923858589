<template>
    <Modal :titulo="`Tracking Mrw-Ve`" @close="toggleModal" :modalActive="true">
        
     <div class="m-auto position-relative">
            <div class="form-floating mb-3">
                <input
				:disabled="loading"
                v-on:keyup.enter="getTracking"
                v-model="guia_courier"
                type="number"
                class="form-control fs-xl-2 b-radius text-center"
                placeholder="Guia Courier"
                required
                />
                <label class="text-center" for="floatingInput">NRO GUIA</label>
            </div>
            <i class="fas fa-search icon-search" @click="getTracking"></i>

      </div>


      <div v-if="loading" class="h-scroll" style="position:relative; top: 1rem;">

           <div class="d-flex" v-for="item in 5" :key="item">

                <div class="d-flex flex-column">
                <span class="number-consult-guia PicLoaderCircular animate"></span>
                <span class="separador-number-guia-loading comment br animate"></span>
                </div>


                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 comment br animate m-auto" style="width: 280px; height: 15px;"></span>
                    <span class="text-muted comment br animate" style="height: 15px;"></span>

                    <span class="text-muted comment br animate" style="height: 4px;"></span>
                </div>

            </div>
      </div>


     <div v-else class="d-flex flex-column">
     
     <div class="table-responsive mb-3" v-if="trackingCount">
     <table class="table">
        <thead class="bg-principal-alt text-white">
            <tr>
                <th>Sucursal Origen</th>
                <th>Sucursal Destino</th>
                <th>Peso</th>
                <th>Cliente</th>
                <th>Cliente DNI</th>
                <th>Cliente TELEFONO</th>
                <th>Destinatario</th>
                <th>Destinatario DNI</th>
                <th>Destinatario TELEFONO</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{tracking.SUCURSAL_ORIGEN}}</td>
                <td>{{tracking.SUCURSAL_DESTINO}}</td>
                <td>{{tracking.PESO}}</td>
                <td>{{tracking.EMISOR_NOMBRE}}</td>
                <td>{{tracking.EMISOR_DNI}}</td>
                <td>{{tracking.EMISOR_TLF}}</td>
                <td>{{tracking.RECEPTOR_NOMBRE}}</td>
                <td>{{tracking.RECEPTOR_DNI}}</td>
                <td>{{tracking.RECEPTOR_TLF}}</td>
            </tr>
        </tbody>
     </table>
     </div>
     

     <div class="d-flex" v-for="(item, index) in tracking.TRANSITO_ENCOMIENDA" :key="item">
     <div class="d-flex flex-column">
     <span class="number-consult-guia">{{trackingCount - index - 1}}</span>
     <span class="separador-number-guia"></span>
     </div>

     <div class="ms-3 d-flex flex-column w-100">
         <div class="fecha d-flex justify-content-between">
             <span>{{item.FECHA}}</span>
             <span>{{item.HORA}}</span>
         </div>
         <span class="text-primary fw-bold text-center mt-2 mb-2">{{item.AGENCIA}}</span>
         <span class="text-muted">{{item.ESTATUS}}</span>
         <div class="hr-border-bottom text-secundary"></div>
     </div>
     </div>
    </div>

    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, onMounted, ref} from 'vue'

import { useToast } from "vue-toastification";
const toast = useToast();

const emit = defineEmits(['toggleModal'])

const guia_courier = ref('')

const tracking = ref([])
const trackingCount = ref(null)

const loading = ref(false)

const toggleModal = () => {
    emit('toggleModal', false)
};


const getTracking = async () => {
    loading.value = !loading.value
    let guiaObj = {guia: guia_courier.value}
    let response = (await axios.post('/tracking/mrw-ve', guiaObj)).data
    loading.value = !loading.value
    if(response.status == 'ok'){
    tracking.value = response.tracking[0]
    trackingCount.value = response.tracking[0].TRANSITO_ENCOMIENDA.length + 1
    }else{
    toast.error("No se obtuvieron trackings de la guia solicitada", 3000)
    tracking.value = []  
    }
}

</script>

<style scoped>
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>