<template>
    <Modal :titulo="`Calculadora Divisas`" @close="toggleModal" :modalActive="true">
        
    <div class="row">
     
       <div class="col-md-12">
             <div class="form-floating mb-3">
              <select class="form-select" v-model="divisaData.tasa_selected" @change="getTasaByDivisaId()">
                <option v-for="item in divisaData.tasas" :key="item.id" :value="item.id">{{item.pais}}</option>
              </select>
              <label for="floatingSelect">Tasas Cambio</label>
            </div>
     </div>
     

        <div class="col-md-12 mb-3">
            <h3>Tasa Actual</h3>
            <h3>{{divisaData.simbolo + divisaData.tasa}}</h3>
        </div>
 
     

            <div class="col-md-6" v-if="divisaData.tasa">
             <div class="form-floating mb-3">
              <select class="form-select" v-model="divisaData.divisa_selected_tengo" @change="calcularDivisa">
                <option v-for="item in divisaData.divisas" :key="item.id" :value="item.divisa">{{item.divisa}}</option>
              </select>
              <label for="floatingSelect">Tengo</label>
            </div>
          </div>

            <div class="col-md-6" v-if="divisaData.tasa">
             <div class="form-floating mb-3">
              <select class="form-select" v-model="divisaData.divisa_selected_quiero" @change="calcularDivisa">
                <option v-for="item in divisaData.divisas" :key="item.id" :value="item.divisa">{{item.divisa}}</option>
              </select>
              <label for="floatingSelect">Quiero</label>
            </div>
         </div>

  
       <div class="col-md-12 position-relative">
            <div class="form-floating mb-3">
                <input
                @keyup="calcularDivisa"
                v-model="divisaData.monto"
                type="number"
                class="form-control fs-xl-2 b-radius text-center"
                />
                <label class="text-center" for="floatingInput">¿Cuanto Conviertes?</label>
            </div>
            <i class="fa-solid fa-calculator icon-calculadora" @click="calcularDivisa"></i>

      </div>




        <div class="col-md-12 mb-3" v-if="divisaData.calculado > 0">
            <h3>Calculo Conversion</h3>
            <h3>{{divisaData.simbolo_conversion + divisaData.calculado}}</h3>
        </div>
 
      
   </div> 
     
    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, reactive, ref, onMounted, computed} from 'vue'
import {getTasasCambio, dataDivisaByPais} from '@/helpers'

const emit = defineEmits(['toggleModal'])


const divisaData = reactive({
    tasa_selected: '',
    divisa_selected_tengo: '',
    divisa_selected_quiero: '',
    tasas: [],
    divisas: [],
    simbolo: '',
    simbolo_conversion: '',
    tasa: '',
    monto: 0,
    calculado: 0
})

const filterTasa = ref([])

const toggleModal = () => {
    emit('toggleModal', false)
};


const getTasaByDivisaId = () => {
   filterTasa.value = divisaData.tasas.filter(item => item.id === divisaData.tasa_selected)[0]
   divisaData.divisas = (dataDivisaByPais(filterTasa.value.pais)).divisas
   divisaData.simbolo = filterTasa.value.divisa_simbolo
   divisaData.tasa = filterTasa.value.tasa
   divisaData.divisa_selected_tengo = ""
   divisaData.divisa_selected_quiero = ""
   divisaData.monto = 0
}


const calcularDivisa = () => {
  if(divisaData.divisa_selected_tengo !== "Dolares" && divisaData.divisa_selected_quiero == "Dolares"){
    divisaData.calculado = computed(() => parseFloat(divisaData.monto / divisaData.tasa).toFixed(2))
  }else{
  if(divisaData.divisa_selected_tengo == "Dolares" && divisaData.divisa_selected_quiero !== "Dolares"){
     divisaData.calculado = computed(() => parseFloat(divisaData.monto * divisaData.tasa).toFixed(2))
  }else{
    divisaData.calculado = computed(() => parseFloat(divisaData.monto).toFixed(2))
  }}
  
  if(divisaData.divisa_selected_quiero){
   divisaData.simbolo_conversion = (divisaData.divisas.filter(item => item.divisa === divisaData.divisa_selected_quiero)[0]).simbolo
  }
  
  }


onMounted(async () => {
    divisaData.tasas = await getTasasCambio()
})
</script>

<style scoped>
.icon-calculadora{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 3rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>