<template>
    <Modal :titulo="`Consulta De Imei Osiptel`" @close="toggleModal" :modalActive="true">
        
     <div class="m-auto position-relative">
            <div class="form-floating mb-3">
                <input
                :disabled="loading"
                v-on:keyup.enter="imeiConsult"
                :maxlength="15"
                v-model="imei"
                type="text"
                class="form-control fs-xl-2 b-radius text-center"
                placeholder="IMEI TELEFONO"
                required
                />
                <label class="text-center" for="floatingInput">NRO IMEI</label>
            </div>
            <i class="fas fa-search icon-search" @click="imeiConsult"></i>

      </div>

      <div v-if="loading || dataImei.status" class="h-scroll" style="position:relative; top: 1rem;">

         <div
             v-if="loading"
            class="d-flex justify-content-center align-items-center"
          >
          <span class="spinner-border me-2 spinner-border-sm" role="status"></span>
          <span>Validando Imei Ingresado...</span>
          </div>


            <div v-else class="row" >
             
            <div class="col-lg-6 d-flex justify-content-center align-items-center">

             <span class="material-icons mb-2" style="font-size: 12rem" v-if="dataImei.status == 'ok' && dataImei.imei_status.includes('15 caracteres')">
             security_update_warning
             </span>

             <span class="material-icons mb-2" style="font-size: 12rem" v-if="dataImei.status == 'error' && dataImei.status_equipo == 'block'">
             screen_lock_portrait
             </span>

            <span class="material-icons mb-2" style="font-size: 12rem" v-else-if="dataImei.status == 'ok' && dataImei.status_equipo == 'free'">
             security_update_good
             </span>

            </div>

            <div class="col-lg-6 d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column">

            <h3 class="text-uppercase fw-bold">{{dataImei.imei_status}}</h3>

            <div class="d-flex justify-content-center mt-2">

            <button class="btn btn-primary b-radius d-flex align-items-center" @click="getImg64Imei">
                <span class="material-icons ms-2">
                image
                </span>
                Ver IMG</button>

            <button class="btn btn-primary ms-2 b-radius d-flex align-items-center" @click="openPdfImei">
                <span class="material-icons ms-2">
                picture_as_pdf
                </span>
                Ver PDF</button>
            </div>
            </div>
            </div>


            <div v-if="loadingImgImei" class="d-flex justify-content-center align-items-center mt-5">
                <span class="spinner-border me-2 spinner-border-sm p-3" role="status"></span>
            </div>


            <div v-else-if="img64Imei.status" class="col-lg-12" >
                <img class="w-100" :src="`data:image/png;base64, ${img64Imei.data}`" alt="img-consulta-imei">
            </div>

            </div>

      </div>
 
    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, ref} from 'vue'
import { useToast } from "vue-toastification";
const toast = useToast();

const emit = defineEmits(['toggleModal'])

const imei = ref('')

const dataImei = ref([])

const img64Imei = ref([])

const loadingImgImei = ref(false)

const loading = ref(false)

const toggleModal = () => {
    emit('toggleModal', false)
};


const imeiConsult = async () => {
    loading.value = !loading.value
    let imeiJson = {imei: imei.value}
    let response = (await axios.post('/services/consulta-imei', imeiJson)).data
    loading.value = !loading.value
    if(response.status == 'ok' || response.status_equipo == 'free' || response.status_equipo == 'block'){
    dataImei.value = response 
    }else{
    toast.error("No se pudo leer la informacion del imei solicitado", 3000)
    dataImei.value = []  
    }
    loadingImgImei.value = false
    img64Imei.value = []  
}


const getImg64Imei = async () => {
    loadingImgImei.value = !loadingImgImei.value
    let imeiJson = {imei: imei.value}
    let response = (await axios.post('/services/consulta-imei/get-img', imeiJson)).data
    loadingImgImei.value = !loadingImgImei.value
    if(response.status == 'ok' || response.status == 'block'){
    img64Imei.value = response 
    }else{
    toast.error("No se pudo ejecutar dicha accion", 3000)
    img64Imei.value = []  
    }
}


const openPdfImei = () => {
    window.open(`${process.env.VUE_APP_URL_API}services/consulta-imei/${imei.value}`)
}
</script>

<style scoped>
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>