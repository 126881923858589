import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import { createPinia } from  'pinia'
import axios from 'axios'
import auth from '@/plugins/auth'
import defaultLayout from '@/layouts/default.vue';
import autenticadoLayout from '@/layouts/autenticado.vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/plugins/chart.js";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import Maska from 'maska'

import {getTimeActual,getDateChartsActual, tiposDocumentos} from '@/helpers'

let jsonHelper = {
  actual_time: getTimeActual().actualTime,
  chart_date: getDateChartsActual().dateChartActual,
  tiposDocs: tiposDocumentos().tipos_documentos,
}

axios.defaults.baseURL = process.env.VUE_APP_URL_API

axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})

axios.interceptors.response.use(response => {
  NProgress.done()
  return response
})

createApp(App)
.provide("helpers",jsonHelper)
.use(createPinia ())
.use(auth)
.use(router)
.use(Maska)
.component('v-select', vSelect)
.use(SimpleTypeahead)
.use(Toast, {
    transition: "Vue-Toastification__fade",
    timeout: 3000,
    position: "top-center",
    hideProgressBar: true,
    maxToasts: 1,
    newestOnTop: false,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(
        t => t.type === toast.type
      ).length !== 0) {
        // Returning false discards the toast
        return false;
      }
      // You can modify the toast if you want
      return toast;
    }
  })
.component("default", defaultLayout)
.component("autenticado", autenticadoLayout)
.mount('#app')
