<template>
    <Modal :titulo="`Consulta De Tracking Tealca`" @close="toggleModal" :modalActive="true">
        
     <div class="m-auto position-relative">
            <div class="form-floating mb-3">
                <input
				:disabled="loading"
                v-on:keyup.enter="getTrackingTealca"
                v-model="guia_courier"
                type="number"
                class="form-control fs-xl-2 b-radius text-center"
                placeholder="Guia Courier"
                required
                />
                <label class="text-center" for="floatingInput">NRO GUIA</label>
            </div>
            <i class="fas fa-search icon-search" @click="getTrackingTealca"></i>

      </div>


      <div v-if="loading || trackingTealca.length > 0" class="h-scroll" style="position:relative; top: 1rem;">

          <div v-if="loading">
           <div class="d-flex" v-for="item in 5" :key="item">

                <div class="d-flex flex-column">
                <span class="number-consult-guia PicLoaderCircular animate"></span>
                <span class="separador-number-guia-loading comment br animate"></span>
                </div>


                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 comment br animate m-auto" style="width: 280px; height: 15px;"></span>
                    <span class="text-muted comment br animate" style="height: 15px;"></span>

                    <span class="text-muted comment br animate" style="height: 4px;"></span>
                </div>

            </div>
          </div>


            <div v-else class="d-flex" v-for="(item, index) in trackingTealca" :key="index">
                <div class="d-flex flex-column">
                <span class="number-consult-guia">{{trackingCount - index - 1}}</span>
                <span class="separador-number-guia"></span>
                </div>

                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span>{{new Date(item.date).toLocaleDateString("en-GB")}}</span>
                        <span>{{new Date(item.date).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric"
                        }) }}</span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 mb-2">{{item.status}}</span>
                    <span class="text-muted">{{item.description}}</span>
                    <div class="hr-border-bottom text-secundary"></div>
                </div>

            </div>

      </div>
 
    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, onMounted, ref} from 'vue'

import { useToast } from "vue-toastification";
const toast = useToast();

const emit = defineEmits(['toggleModal'])

const guia_courier = ref('')

const trackingTealca = ref([])
const trackingCount = ref(0)

const loading = ref(false)

const toggleModal = () => {
    emit('toggleModal', false)
};


const getTrackingTealca = async () => {
    loading.value = !loading.value
    let guiaObj = {guia: guia_courier.value}
    let response = (await axios.post('/tracking/tealca', guiaObj)).data
    loading.value = !loading.value
    if(response.status == 'ok'){
    trackingTealca.value = response.guia.tracking 
    trackingCount.value = response.guia.tracking.length + 1
    }else{
    toast.error("No se obtuvo trackings en la guia solicitada", 3000)
    trackingTealca.value = []  
    }
}

</script>

<style scoped>
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>